@import '~@devsta/ui-kit/scss/core/vars';

.user-availability {
  box-sizing: border-box;
  margin-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;

  &__editors {
    align-items: center;
    display: flex;

    > *:first-child {
      margin-right: 5px;
    }
  }

  &__line {
    background: $big-fat;
    border-radius: 12px;
    height: 5px;
    margin-bottom: 10px;
    overflow: hidden;
    width: 100%;

    &--progress {
      background: $monkey;
      height: 100%;
      transition: width 0.5s;
    }
  }

  &__count {
    font-size: $font-sizes-small;
    font-weight: $font-weights-medium;
    margin: 0;
  }

  &__subtitle {
    color: $regent;
    font-size: $font-sizes-micro;
    margin: 0;
  }

  &__horizontal-rule {
    height: 1px;
    margin: 0;
    opacity: 0.5;
    padding-top: 20px;
    width: 100%;
  }

  &__availability {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;

    > p {
      font-size: $font-sizes-small;
      font-weight: $font-weights-medium;
      margin: 0;
    }
  }
}
