@import '~@devsta/ui-kit/scss/core/vars';

.notifications-summary {
  width: 300px;

  &__container {
    position: relative;
  }

  &__items {
    max-height: 40vh;
    overflow-y: auto;
  }

  &__view-all-button {
    margin: 10px auto 0;
    width: 80%;
  }

  &__indicator {
    align-items: center;
    background-color: $monkey;
    border: 2px solid $white;
    border-radius: 50%;
    color: $white;
    display: flex;
    height: 14px;
    justify-content: center;
    position: absolute;
    right: -9px;
    top: -9px;
    width: 14px;

    &__text {
      align-items: center;
      display: flex;
      font-size: 8px;
      height: 100%;
      justify-content: center;
      width: 100%;
    }
  }

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
  }

  .unread-notification {
    background-color: $sterling;
  }

  &__item {
    align-items: center;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    text-decoration: none;

    &__unread {
      background: $monkey;
      border-radius: 50%;
      height: 10px;
      margin-left: 20px;
      min-width: 10px;
      width: 10px;
    }

    &:hover {
      background: $parthenon;
    }

    &__content {
      &__text {
        display: flex;
        margin: 0;
      }

      &__updated {
        margin: 0;
        opacity: 0.3;
        padding: 0 0 10px 20px;
      }
    }
  }

  &__no-items {
    font-size: $font-sizes-small;
    padding: 50px 30px 15px;
    text-align: center;
  }
}
