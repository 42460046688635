@import '~@devsta/ui-kit/scss/core/vars';

.login-header {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 45px;
  margin-top: auto;

  > .text {
    white-space: nowrap;
  }

  > .icon-container {
    margin-bottom: 45px;
  }
}
