@import '~@devsta/ui-kit/scss/core/vars';

.organisations-list {
  display: flex;
  flex-direction: column;

  &__search {
    margin-bottom: 20px;
  }

  &__title-text {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }

  &__list-container {
    background: #fff;
    border: 1px solid $cat-skill-white;
    border-radius: 6px;
    flex: 1 1;
    max-height: 365px;
    overflow-y: scroll;
    padding: 20px;
    padding-top: 0;

    &__empty-text {
      color: $regent;
      margin: 0;
      padding-top: 20px;
      text-align: center;
    }
  }

  &__list-item {
    align-items: center;
    border-bottom: 1px solid $russian;
    border-bottom-color: rgb(212, 217, 226);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-top: 20px;

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }

    > .image {
      margin-right: 20px;
    }

    &__content-desc {
      align-items: center;
      display: flex;
      flex: 1 1;
    }
  }
}
