@import '~@devsta/ui-kit/scss/core/vars';
@import '../mixins/login-form-mixin';

.forgot-password {
  @include login-form-mixin;

  &__container {
    display: flex;
    flex-direction: column;
  }
}
