@import '~@devsta/ui-kit/scss/core/vars';

@mixin login-form-mixin() {
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 80%;

  &__close-btn {
    left: 0;
    position: absolute;
    top: 30px;
  }

  @media only screen and (max-width: $media-sizes-small) {
    width: 100%;

    &__close-btn {
      top: 10px;
    }
  }

  &__field {
    display: block;
    max-width: 500px;
    width: 100% !important;

    &:first-of-type {
      margin-bottom: 20px;
    }
  }

  &__submit {
    margin-top: 50px;
  }
}
