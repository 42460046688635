@import '~@devsta/ui-kit/scss/core/vars';
@import '~@devsta/ui-kit/scss/core/mixins';
@import '../mixins/login-form-mixin';

.login-form {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  text-align: center;

  &__body {
    @include login-form-mixin;


    align-items: flex-start;

    > .login-header {
      text-align: left;

      > .icon-container {
        justify-content: flex-start;
      }
    }


    &__account {
      font-size: $font-sizes-small;
      margin-bottom: 0;
      margin-top: 50px;
      text-align: left;
    }

    &__form {
      align-items: flex-start;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      width: 100%;
    }

    &__forgot-btn {
      margin-top: 10px;
    }

    &__error {
      align-items: center;
      display: flex;

      > p {
        font-size: $font-sizes-small;
        margin-bottom: 0;
        margin-left: 10px;

        @media only screen and (max-width: $media-sizes-small) {
          margin: 0;
        }
      }
    }
  }

  &__footer {
    box-sizing: border-box;
    display: flex;
    margin-bottom: 10px;
    margin-top: auto;
    width: 100%;

    > p {
      font-size: 15px;
      margin: 0;
      margin-right: 10px;
    }
  }
}
