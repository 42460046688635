@import '~@devsta/ui-kit/scss/core/vars';

.welcome-guest {
  .rodal-dialog {
    min-width: initial !important;
  }

  &__footer {
    font-size: $font-sizes-small;
    letter-spacing: 1px;
    line-height: 17px;
    margin-top: 20px;

    &__link {
      cursor: pointer;
      display: inline-block;
      margin: 0;
      text-decoration: underline;
    }
  }

  &__modal {
    box-sizing: border-box;
    max-height: 530px;
    max-width: 920px;
    overflow-y: auto;
    transition: max-width 0.2s linear;

    &-login {
      max-width: 500px;
      min-width: auto;
    }
  }

  &__header {
    display: flex;
    margin-bottom: 30px;

    > .icon-container {
      position: relative;

      &::before {
        background-color: $monkey;
        border-radius: 50%;
        content: '';
        height: 35px;
        position: absolute;
        width: 35px;
        z-index: 0;
      }

      > .icon {
        position: absolute;
      }
     
    }
  }

  &__title {
    padding-left: 25px;
  }

  &__primary-title {
    color: $black;
    font-size: $font-sizes-large;
    margin: 0;
  }

  &__secondary-title {
    color: $black;
    font-size: $font-sizes-small;
    letter-spacing: 1px;
    margin: 0;
  }

  &__body {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;

    &__error {
      align-items: flex-start;
      color: $thunder-bird;
      display: flex;
      margin: auto;
      padding-top: 15px;
      white-space: nowrap;

      > .icon-container {
        padding-right: 5px;
      }
    }

    &__left-content {
      margin-right: 30px;
    }

    &__right-content {
      > .image {
        display: block;
        margin-bottom: 25px;
      }

      &__primary-text {
        color: $black;
        font-size: $font-sizes-default;
        letter-spacing: 1px;
        line-height: 20px;
        margin-bottom: 5px;
        text-align: center;
      }

      &__secondary-text {
        color: $black;
        font-size: $font-sizes-small;
        letter-spacing: 1px;
        line-height: 17px;
        margin-top: 5px;
      }
    }

    &__button {
      width: 395px;
    }

    &__text {
      margin-bottom: 12px;
      margin-top: 12px;
      text-align: center;
    }
    
    &__input {
      input {
        margin-bottom: 15px;
        width: 395px;
      }

      &:last-of-type {
        input {
          margin-bottom: 25px;
        }
      }
    }
    
  }

  @media only screen and (max-width : $media-sizes-small) {
    &__body {
      display: block;

      &__input {
        input {
          width: 100%;
        }
      }

      &__left-content {
        margin-right: 0;
      }

      &__right-content {
        display: none;
      }

      &__button {
        width: 100%;
      }
    }

    &__footer {
      display: flex;
      justify-content: center;
    }
  }
}
