@import '~@devsta/ui-kit/scss/core/vars';

.login-form {
  &__body {
    width: 360px;

    &__error {
      align-items: center;
      color: $thunder-bird;
      display: flex;
      justify-content: space-evenly;
      padding-top: 15px;
      white-space: nowrap;
    }
  }

  &__horizontal-line {
    border: 2px solid $cat-skill-white;
    margin: 0;
    width: 100%;
  }

  &__text {
    color: $black;
    font-size: $font-sizes-micro;
    letter-spacing: 1px;
    line-height: 15px;
    margin-bottom: 0;
    margin-top: 20px;
  }

  &__button {
    margin-bottom: 20px;
    margin-top: 30px;
    width: 100%;
  }

  &__cancel-button {
    display: block;
    margin: 0;
  }

  &__title {
    font-size: $font-sizes-large;
    letter-spacing: 2px;
    line-height: 25px;
    margin-bottom: 40px;
    text-align: center;
  }

  &__input {
    input {
      margin-bottom: 15px;
      width: 100%;
    }

    &:last-of-type {
      input {
        margin-bottom: 25px;
        width: 100%;
      }
    }
  }

  @media only screen and (max-width : $media-sizes-small) {
    &__body {
      box-sizing: border-box;
      width: 100%;
    }
  }
}
