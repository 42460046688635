@import '~@devsta/ui-kit/scss/core/vars';

header {
  background-color: $white;
  height: 60px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 4;

  .global-header-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    padding-top: 1px;

    > .notifications-summary {
      &__container {
        margin-right: 30px;
      }
    }

    > .global-credits-wrapper {
      margin-right: 30px;
      padding-right: 20px;
      position: relative;

      &::after {
        background: $russian;
        content: '';
        height: 30px;
        position: absolute;
        right: 0;
        width: 1px;
      }
    }
  }
}
