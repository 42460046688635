@import '~@devsta/ui-kit/scss/core/vars';

.outputs-list {
  box-sizing: border-box;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;

  &__date {
    font-size: $font-sizes-micro;
  }

  &__output {
    display: flex;
    width: 100%;

    &__info {
      flex: 1;
      margin-left: 10px;

      &__name {
        font-size: $font-sizes-small;
        font-weight: $font-weights-medium;
        margin: 0;
      }

      &__status {
        font-size: $font-sizes-micro;
        font-weight: $font-weights-bold;
        margin: 0;
        text-transform: uppercase;
      }
    }

    &__time-left {
      font-size: $font-sizes-small;
      font-weight: $font-weights-bold;
      margin: 0 0 0 10px;
    }
  }
}
