@import '~@devsta/ui-kit/scss/core/vars';

.user-summary {
  &__container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    min-width: 250px;

    > .dropdown-option + .dropdown-option {
      margin-top: 5px;
    }

    > hr {
      border-color: $parthenon !important;
      margin-bottom: 10px;
      margin-top: 10px;
      width: 80% !important;
    }

    &__nav {
      display: flex;
      flex-direction: column;
      list-style-type: none;
      margin-bottom: 0;
      margin-left: 0;
      padding-top: 21px;
      white-space: nowrap;

      &__link {
        padding-bottom: 0;

        &--sign-out > span {
          color: $red-apple !important;
        }

        &:hover {
          color: $black;
        }
      }
    }
  }

  &__organisation-info {
    align-items: center;
    display: flex;

    > .image {
      margin-right: 10px;
    }
  }
}
